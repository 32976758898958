exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dosing-index-js": () => import("./../../../src/pages/dosing/index.js" /* webpackChunkName: "component---src-pages-dosing-index-js" */),
  "component---src-pages-efficacy-safety-index-js": () => import("./../../../src/pages/efficacy-safety/index.js" /* webpackChunkName: "component---src-pages-efficacy-safety-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mechanism-of-action-index-js": () => import("./../../../src/pages/mechanism-of-action/index.js" /* webpackChunkName: "component---src-pages-mechanism-of-action-index-js" */),
  "component---src-pages-order-ryzumvi-index-js": () => import("./../../../src/pages/order-ryzumvi/index.js" /* webpackChunkName: "component---src-pages-order-ryzumvi-index-js" */),
  "component---src-pages-patient-dilation-challenges-index-js": () => import("./../../../src/pages/patient-dilation-challenges/index.js" /* webpackChunkName: "component---src-pages-patient-dilation-challenges-index-js" */)
}

