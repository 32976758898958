import React from "react";
import { AppProvider } from "./src/context";
import GTM from "@utils/GTM";

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/900.css";

import "@fontsource/poppins/400-italic.css";
import "@fontsource/poppins/500-italic.css";
import "@fontsource/poppins/600-italic.css";
import "@fontsource/poppins/700-italic.css";
import "@fontsource/poppins/800-italic.css";
import "@fontsource/poppins/900-italic.css";

import "@styles/fonts.css";
import "@styles/cookies.css";
import "@styles/global.css";
import "@styles/zoom.css";
import "@styles/screenshots.css";

export const onRouteUpdate = () => {
  GTM.reset();
};

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);
