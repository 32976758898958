import { v4 as uuidv4 } from "uuid";

export const isEDMode =
  typeof window !== "undefined"
    ? window.location.href.indexOf("ed=1") > 0
    : false;
export const mobileMedia = "(min-width: 0px) and (max-width: 1200px)";
export const tabletMedia = "(min-width: 768px) and (max-width: 1200px)";

export const isMobile = (media = false) => {
  if (typeof window !== "undefined") {
    return window.matchMedia(media || mobileMedia).matches;
  }

  return true;
};

export const isTablet = () => {
  if (typeof window !== "undefined") {
    return window.matchMedia(tabletMedia).matches;
  }

  return true;
};

export const bounds = (elem) => {
  const rect = elem.getBoundingClientRect();

  return {
    top: rect.top,
    left: rect.left,
    right: rect.right,
    bottom: rect.bottom,
    width: rect.width,
    height: rect.height,
  };
};

export const getWinSize = () => {
  if (typeof window !== "undefined") {
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName("body")[0];
    const x = w.innerWidth || e.clientWidth || g.clientWidth;
    const y = w.innerHeight || e.clientHeight || g.clientHeight;

    return { width: x, height: y };
  }

  return { width: 0, height: 0 };
};

export const inViewport = (
  elem,
  entireBox = false,
  xOffset = 0,
  yOffset = 0,
) => {
  if (typeof window !== "undefined") {
    const bds = bounds(elem);
    const doc = getWinSize();

    if (!entireBox) {
      return (
        bds.top >= 0 + yOffset &&
        bds.left >= 0 + xOffset &&
        bds.top <= doc.height &&
        bds.left <= doc.width
      );
    } else {
      const topLimit = bds.top - doc.height;
      const bottomLimit = bds.top + bds.height;
      const leftLimit = bds.left - doc.width;
      const rightLimit = bds.left + bds.width;

      if (
        topLimit <= 0 &&
        bottomLimit >= 0 &&
        leftLimit <= 0 &&
        rightLimit >= 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  return false;
};

const timeoutDebounce = {};
export const debounce = (name, func, wait, immediate) => {
  return function () {
    const context = this;
    const args = arguments;

    const later = () => {
      timeoutDebounce[name] = null;

      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeoutDebounce[name];

    clearTimeout(timeoutDebounce[name]);

    timeoutDebounce[name] = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
};

export const focusLoop = (e, selector) => {
  const tabPressed = e.key === "Tab" || e.keyCode === 9;

  if (!tabPressed) return;

  const elems = document.querySelectorAll(selector);

  if (elems?.length) {
    const firstElem = elems[0];
    const lastElem = elems[elems.length - 1];

    if (!Array.from(elems).includes(document.activeElement)) {
      firstElem.focus();
    }

    if (e.shiftKey) {
      if (document.activeElement === firstElem) {
        lastElem.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastElem) {
        firstElem.focus();
        e.preventDefault();
      }
    }
  }
};

export const getCookieByName = (cookieName) => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(cookieName + "="));
  if (cookieValue === undefined) {
    return null;
  }
  return cookieValue.split("=")[1];
};

export const checkSettingsBtns = () => {
  const allowAllBtn = document.body.querySelector(
    "#accept-recommended-btn-handler",
  );
  const targetChecked = document.querySelector("#ot-group-id-C0002").checked;
  const performanceChecked =
    document.querySelector("#ot-group-id-C0004").checked;

  if (targetChecked && performanceChecked) {
    allowAllBtn.style.display = "none";
  } else {
    allowAllBtn.style.display = "inline-block";
  }
};

export const getUrlParameter = (param) => {
  param = param.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + param + "=([^&#]*)");
  const results = regex.exec(window.location.search);

  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const scrollToSection = (selector) => {
  if (typeof document !== "undefined") {
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }
};

export const openCookieModal = () => {
  if (typeof window !== "undefined" && "OneTrust" in window) {
    window.OneTrust.ToggleInfoDisplay();
  }
};

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

export const extractTextFromElement = (element) => {
  if (element?.props && element?.props?.children) {
    if (typeof element.props.children === "string") {
      return element.props.children;
    } else if (Array.isArray(element.props.children)) {
      const textContentArray = element.props.children.map((child) => {
        if (typeof child === "string") {
          return child;
        } else if (typeof child === "object" && child !== null) {
          return extractTextFromElement(child);
        } else {
          return "";
        }
      });

      return textContentArray.join(" ");
    }
  } else {
    return element;
  }
};

export const getToken = () => {
  // Check if the uuid is already in local storage
  if (typeof window !== "undefined") {
    const uuid = localStorage.getItem("uuid");
    if (uuid) {
      return uuid;
    }

    // if not, generate a new uuid
    const uuid_v4 = uuidv4();
    // save the uuid to local storage
    localStorage.setItem("uuid", uuid_v4);
    return uuid_v4;
  }
};
