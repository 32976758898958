import { debounce, extractTextFromElement } from "@utils/helper.js";

let distanceScrolled = -1;
let distanceThreshold = [
  { threshold: 0, value: "Baseline", sent: false },
  { threshold: 25, value: "25%", sent: false },
  { threshold: 50, value: "50%", sent: false },
  { threshold: 75, value: "75%", sent: false },
  { threshold: 100, value: "100%", sent: false },
];

const scrollDepth = () => {
  const scrollTop =
    document.body.scrollTop || document.documentElement.scrollTop;
  let progress = Math.round(
    (scrollTop / (document.body.scrollHeight - window.innerHeight)) * 100,
  );

  if (isNaN(progress)) progress = 0;

  if (progress > distanceScrolled) {
    distanceScrolled = progress;

    for (let i = 0; i < distanceThreshold.length; i++) {
      const status = distanceThreshold[i].sent;
      const threshold = distanceThreshold[i].threshold;
      const value = distanceThreshold[i].value;

      if (!status && progress >= threshold) {
        if ("dataLayer" in window) {
          (function checkOnetrustActiveGroups() {
            if (window.OnetrustActiveGroups) {
              window.dataLayer.push({
                event: "gatsbyScroll",
                gatsbyScrollDistance: value,
              });
            } else {
              setTimeout(checkOnetrustActiveGroups, 100); // Check again after 100ms
            }
          })();
          distanceThreshold[i].sent = true;
        }
      }
    }
  }
};

const scrollListener = debounce("scrollDepth", scrollDepth, 18);

// ISI Scroll
let distanceScrolledIsi = -1;
let distanceThresholdIsi = [
  { threshold: 25, value: "25%", sent: false },
  { threshold: 50, value: "50%", sent: false },
  { threshold: 75, value: "75%", sent: false },
  { threshold: 100, value: "100%", sent: false },
];

const scrollDepthIsi = () => {
  const sidebarIsi = document.querySelector("#sidebar-isi");
  const scrollTop = sidebarIsi.scrollTop;

  let progress = Math.round(
    (scrollTop / (sidebarIsi.scrollHeight - sidebarIsi.clientHeight)) * 100,
  );
  if (isNaN(progress)) progress = 0;

  if (progress > distanceScrolledIsi) {
    distanceScrolledIsi = progress;

    for (let i = 0; i < distanceThresholdIsi.length; i++) {
      const status = distanceThresholdIsi[i].sent;
      const threshold = distanceThresholdIsi[i].threshold;
      const value = distanceThresholdIsi[i].value;

      if (!status && progress >= threshold) {
        if ("dataLayer" in window) {
          window.dataLayer.push({
            event: "isiScroll",
            isiScrollDistance: value,
          });
          distanceThresholdIsi[i].sent = true;
        }
      }
    }
  }
};

const scrollListenerIsi = debounce("scrollDepthIsi", scrollDepthIsi, 18);

const GTM = {
  init: () => {
    window.dataLayer = window.dataLayer || [];
    const interval = setInterval(() => {
      if ("dataLayer" in window && "google_tag_manager" in window) {
        window.dataLayer.push({
          event: "custom-gatsby-route-change",
          currentPagePath: window.location.pathname,
        });
        clearInterval(interval);
      }
    }, 100);
    scrollDepth();
    document.addEventListener("scroll", scrollListener);
  },
  initIsi: () => {
    scrollDepthIsi();
    const sidebarIsi = document.querySelector("#sidebar-isi");
    if (sidebarIsi) {
      sidebarIsi.addEventListener("scroll", scrollListenerIsi);
    }
  },
  visitorID: (token) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "VisitorIdGenerate",
      visitorID: token,
    });
  },
  modal: (action, label, modalType) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "modalChange",
      modalAction: action,
      exitUrl: label,
      modalType: modalType,
    });
  },
  // Expand/Collapse START
  toggleMobileMenu: (isExpanded) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "toggleMobileMenu",
        mobileMenuState: isExpanded ? "Expand" : "Collapse",
      });
    }
  },
  toggleAccordion: (isExpanded, accHeading, page) => {
    // Not all accordion headings are text, some are react elements, handle them accordingly
    const accHeadingText = extractTextFromElement(accHeading);
    window.dataLayer.push({
      event: "toggleAccordion",
      accordionEventName: accHeadingText,
      accordionState: isExpanded ? "Collapse" : "Expand",
    });
  },
  // Expand/Collapse END

  // video tags START
  videoPlay: (videoName) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "videoPlay",
        videoName: videoName,
      });
    }
  },

  videoEnded: (videoName) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "videoEnded",
        videoName: videoName,
      });
    }
  },

  videoInterval: (videoName, currentTime) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "videoInterval",
        videoName: videoName,
        videoCurrentTime: currentTime,
      });
    }
  },

  videoPercentage: (videoName, percentage) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "videoPercentage",
        videoName: videoName,
        videoPercentage: percentage,
      });
    }
  },
  // video tags END

  isiCollapse: () => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "isiCollapse",
      });
    }
  },

  // form tags START
  formSubmission: () => {
    if ("dataLayer" in window) {
      window.dataLayer.push({ event: "formCompletion" });
    }
  },
  formInputChange: (field, value) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "inputChange",
        fieldName: field,
        fieldValue: value && value.length > 0 ? "_" + value : "",
      });
    }
  },
  formInputError: (field, value) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "formInputError",
        fieldName: field,
        fieldError: value,
      });
    }
  },
  formFieldCompletionFormSubmitted: (field, value) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "formFieldCompletionFormSubmitted",
        fieldName: field,
        fieldValue: value && value.length > 0 ? "_" + value : "",
      });
    }
  },
  // form tags END
  reset: () => {
    distanceScrolled = -1;
    distanceThreshold.forEach((threshold) => (threshold.sent = false));
    distanceScrolledIsi = -1;
    distanceThresholdIsi.forEach((threshold) => (threshold.sent = false));
  },
};

export default GTM;
